import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { ref } from 'vue';

export default () => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [];
  const columnList: CrmTableOptions[] = [
    {
      label: '客户合同号',
      prop: 'contractNo',
      minWidth: 180,
    },
    {
      label: '商机名称',
      prop: 'businessName',
      minWidth: 220,
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 220,
    },
    {
      label: '占用额度(元)',
      prop: 'leftAmountDesc',
      minWidth: 242,
    },
    {
      label: '资金到期日期',
      prop: 'fundEndDate',
      minWidth: 160,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
